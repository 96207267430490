<template>
    <div class="grid">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  Clients
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  iconPos="right"
                  label="Add"
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Add'"
                  @click="openNew"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card" id="custom_card">
          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <span class="p-float-label">
                    <InputText
                      format="text"
                      v-model="search_filter"
                      @input="search(1)"
                    >
                    </InputText>
                    <label>Search Clients</label>
                  </span>
                </div>
                <div class="field col-2 md:col-2">
                
                  <Button
                    icon="pi pi-refresh"
                    v-tooltip.bottom="'Refresh'"
                    @click="search(2)"
                    class="p-button-danger"
                    style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;"
                  />
                </div>
              </div>
            </div>
          </div>
  
          <DataTable
            :loading="loading"
            ref="dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="products"
            v-model:selection="selectedProducts"
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            style="font-size: 12px"
            :rowHover="true"
            showGridlines
          >
            <template #empty>
              <b style="text-align: center">No records found.</b>
            </template>
            <template #header>
              <!-- <div
                class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
              >
                <h5 class="m-0"><b> Clients Master</b></h5>
                <Button
                  icon="pi pi-refresh"
                  v-tooltip.bottom="'Refresh'"
                  @click="search(2)"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;"
                />
               
              </div> -->
              <div style="text-align: right; font-weight: bold">
                <span>Total Users:</span>{{ totalRecords }}
              </div>
            </template>
            <Column header="Logo" style="min-width: 5rem">
              <template #body="{ data }">
                <img
                    style="border-radius: 50%; width: 50px; height: 50px"
                    v-if="data.logo"
                    :src="data.logo"
                    :alt="data.logo"
                  />
              </template>
            </Column>
            <Column header="Name" style="min-width: 5rem">
              <template #body="{ data }">
                {{ data.name }}
              </template>
            </Column>
            <Column
              header="Application Name"
              :field="columns[3]"
              style="min-width: 5rem"
            >
              <template #body="{ data }">
                {{ data.application_name }}
              </template>
            </Column>
            <Column
              header="Email ID"
              :field="columns[3]"
              style="min-width: 5rem"
            >
              <template #body="{ data }">
                {{ data.email_id }}
              </template>
            </Column>
            <Column
              header="Mobile No"
              :field="columns[3]"
              style="min-width: 5rem"
            >
              <template #body="{ data }">
                {{ data.mobile_number }}
              </template>
            </Column>
            <!-- <Column
              header="Client Type"
              :field="columns[3]"
              style="min-width: 5rem"
            >
              <template #body="{ data }">
                {{ data.client_type }}
              </template>
            </Column> -->
            <Column
              header="Current Version"
              :field="columns[3]"
              style="min-width: 5rem"
            >
              <template #body="{ data }">
                {{ data.current_version }}
              </template>
            </Column>
            <Column header="Action" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  title="Edit Client"
                  v-tooltip.bottom="'Edit'"
                  class="p-button-rounded p-button-help mr-2"
                  @click="editProduct(slotProps.data)"
                  style="
                      box-shadow: 0 2px 6px #ffc107;
                      border-color: #ffc107 !important;
                      background-color: #ffc107 !important;
                    "
                />
                <Button
                  icon="pi pi-trash"
                  v-tooltip.bottom="'Delete'"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
  
      <Dialog
        v-model:visible="productDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '55vw' }"
        header="Clients"
        :modal="true"
        position="top"
      >
        <div class="col-12">
          <div class="p-fluid formgrid grid" style="margin-top: 2%">
            <div class="field col-12 md:col-12" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="product.name"
                  />
                  <label for="ticket_title">Client Name</label>
                </span>
              </div>
            <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="product.application_name"
                  />
                  <label for="ticket_title">Application Name</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="product.email_id"
                  />
                  <label for="ticket_title">Email</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <!-- <vue-tel-input v-model="products.mobile_number" mode="international"></vue-tel-input> -->
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    maxlength="10"
                    v-model="product.mobile_number"
                  />
                  <label for="ticket_title">Phone</label>
                </span>
              </div>

              <!-- <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode ==43"
                    maxlength="3"
                    v-model="product.country_code"
                  />
                  <label for="ticket_title">Country Code</label>
                </span>
              </div> -->

              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <Dropdown
                      id="country_data"
                      v-model="country_selected"
                      :options="country_data"
                      :value="country_data.dial_code"
                      optionLabel="dial_code"
                      :filter="true"
                    >
                    <template #value="slotProps">
                      <div class="flex align-items-center" v-if="slotProps.value">
                        <span :class="'mr-2 flag flag-' + slotProps.value.code.toLowerCase()" style="width:18px; height: 12px"/>
                        <div>{{slotProps.value.name}}</div>&nbsp;
                        <div>{{slotProps.value.dial_code}}</div>
                        
                      </div>
                    
                    </template>
                    <template #option="slotProps">
                      <div class="flex align-items-center">
                        <span :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px; height: 12px"/>
                        <div>{{slotProps.option.name}}</div>&nbsp;
                        <div>{{slotProps.option.dial_code}}</div>
                        
                      </div>
                    </template>
                  </Dropdown>
                  <label for="ticket_title">Country Code</label>
                </span>
              </div>

              <div class="field col-12 md:col-12" style="margin-top: 2%">
                <span class="p-float-label">
                  <Textarea
                    rows="3"
                    id="ticket_title"
                    type="text"
                    v-model="product.about_us"
                  />
                  <label for="ticket_title">About</label>
                </span>
              </div>
              <div class="field col-12 md:col-12" style="margin-top: 2%">
                <span class="p-float-label">
                  <Textarea
                    rows="3"
                    id="ticket_title"
                    type="text"
                    v-model="product.address"
                  />
                  <label for="ticket_title">Address</label>
                </span>
              </div>
              <!-- =================================================================================== -->
              <div class="field col-12 md:col-2" style="margin-top: 2%">
                <FileUpload
                  class="p-button-primary"
                  mode="basic"
                  name="model[]"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                  :auto="true"
                  :custom-upload="true"
                  choose-label="Logo"
                  @uploader="handleFileUpload1($event)"
                />
              </div>
              <div class="field col-12 md:col-2" style="margin-top: 1%">
                <img
                  :src="product.logo"
                  :alt="product.logo"
                  v-if="product.logo"
                  class="shadow-2"
                  width="70"
                />
              </div>

            <!-- =================================================================================== -->
              <div class="field col-12 md:col-2" style="margin-top: 2%">
                <FileUpload
                  class="p-button-primary"
                  mode="basic"
                  name="model[]"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                  :auto="true"
                  :custom-upload="true"
                  choose-label="Fevicon"
                  @uploader="handleFileUpload2($event)"
                />
              </div>
              <div class="field col-12 md:col-2" style="margin-top: 1%">
                <img
                  :src="product.fevicon"
                  :alt="product.fevicon"
                  v-if="product.fevicon"
                  class="shadow-2"
                  width="70"
                />
              </div>
            <!-- =================================================================================== -->
              <div class="field col-12 md:col-3" style="margin-top: 2%">
                <!-- <p>Current Version</p>
                <p>{{ product.current_version }}</p> -->
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="product.current_version"
                  />
                  <label for="ticket_title">Current Version</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-facebook"></i>
                  </span>
                  <InputText
                    placeholder="Facebook URL"
                    v-model="product.facebook_url"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-twitter"></i>
                  </span>
                  <InputText
                    placeholder="Twitter URL"
                    v-model="product.twitter_url"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-linkedin"></i>
                  </span>
                  <InputText
                    placeholder="LinkedIn URL"
                    v-model="product.linkedin_url"
                  />
                </div>
              </div>
          </div>
        </div>
  
        <template #footer>
          <Button
            label="Save"
            @click="save_client"
            icon="pi pi-check"
            class="p-button-success"
          />
        </template>
      </Dialog>
  
      <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete <b>{{ product.name }}</b
                >?</span
              >
            </div>

            <div class="col-12">
                <div class="p-fluid formgrid grid" style="margin-top: 2%">
                    <div class="field col-12 md:col-12" style="margin-top: 2%">
                    <span class="p-float-label">
                        <Textarea
                        id="Remark"
                        type="text"
                        v-model="product.remark"
                        />
                        <label for="Remark">Remark</label>
                    </span>
                    </div>
                </div>
            </div>

            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteclient"
              />
            </template>
          </Dialog>
    </div>
  </template>
    <script>
  import { FilterMatchMode } from "primevue/api";
  import ProductService from "../service/ProductService";
  import CountryCodeService from "../service/CountryCodeService";
  
  import apis from "@/apis";
  import axios from "axios";
  export default {
    data() {
      return {
          search_filter:"",
        user_key: "",
        page_no: 1,
        isLoading: false,
        isLoadingModel: false,
        loading: false,
        fullPage: true,
        products: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        lazyParams: {},
        product: {},
        selectedProducts: null,
        filters: {},
        offset: 1,
        limit: 10,
        columns: [],
        pageno: 1,
        submitted: false,
        totalRecords: 0,
        id: "",
        CountryCodeService:null,
        country_data:[],
        country_selected:"",
        countryid:""
      };
    },
    productService: null,
    async created() {
      this.productService = new ProductService();
      this.initFilters();
      this.user_key = localStorage.getItem("user_type");
    },
    async mounted() {

      this.CountryCodeService = new CountryCodeService();
      await this.CountryCodeService.GetCountryCode().then((data) => {
        this.country_data = data;
      });

      this.id = this.$route.params.id;
      this.lazyParams = {
        first: 0,
        rows: this.limit,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      };
      if (this.totalRecords == 0) {
        await this.get_count();
      }
      await this.get_list();
    },
    watch: {
      async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    watch: {
      currentLocale(){
      this.getWardList(this.currentLocale);
      this.get_list(this.currentLocale);
      //this.save_cat(this.currentLocale);
      //this.deleteWard(this.currentLocale);
      if (this.currentLocale == 'en') {
        this.jsonFields={
          "Sr No": "sr_no",
          "Ward No" : "ward_no" ,
          "Name" : "name" ,
          "Total Ticket" : "allticket",
          "Open Ticket" : "open_ticket",
          "In Progress Ticket" : "inprogress_ticket",
          "close Ticket" : "closed_ticket",
          "Status" : "status",
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
          "प्रभाग क्र" : "ward_no" ,
          "नाव" : "name" ,
          "एकूण तिकीट" : "allticket",
          "नवीन तक्रार" : "open_ticket",
          "प्रगतीपथावर असलेले तिकीट" : "inprogress_ticket",
          "एकूण बंद तक्रार" : "closed_ticket",
          "स्थिती" : "status",
        }
      }
     
      
    },
      async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
    methods: {
        save_client()
        {
          this.countryid = "";
          this.countryid = this.country_selected.dial_code?this.country_selected.dial_code.toString():"";
          console.log("code:",this.countryid);
            var name_regex = /^\s*$/;
            var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            var mobile_reg=/^[0-9]{10}$/

            if (!this.product.name) 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
               // detail: "Please Enter client Name ",
                detail: this.$t("enter_client_name"),

                life: 3000,
            });
            return false;
            }

            if (this.product.name) 
            {   
                if (name_regex.test(this.product.name)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        //detail: "Please Enter Valid Client Name",
                        detail: this.$t("enter_valid_client_name"),
                        life: 3000,
                    });
                    return false;
                }                
            }

            if (!this.product.application_name) 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
               // detail: "Please Enter Application Name ",
                detail: this.$t("enter_application_name"),
                life: 3000,
            });
            return false;
            }

            if (this.product.application_name) 
            {   
                if (name_regex.test(this.product.application_name)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        //detail: "Please Enter Valid Application Name",
                        detail: this.$t("enter_valid_application_name"),
                        life: 3000,
                    });
                    return false;
                }                
            }

            if (this.product.email_id=="" || this.product.email_id==undefined) 
            {
            this.$toast.add({
                severity: "error",
                summary: "error",
                //detail: "Please Enter Email ID",
                detail:this.$t("enter_email_id"),
                life: 3000,
            });
            return false;
            }

            if (this.product.email_id) 
            {   
                if (!emailreg.test(this.product.email_id)) 
                {
                        this.$toast.add({
                        severity: "error",
                        summary: "error",
                        //detail: "Please Enter Valid Email ID",
                        detail:this.$t("enter_valid_email_id"),
                        life: 3000,
                    });
                    return false;
                }                
            }

            if (this.product.mobile_number=='' || this.product.mobile_number==undefined) 
            {   
              this.$toast.add({
                  severity: "error",
                  summary: "error",
                 // detail: "Please enter mobile number",
                  detail:this.$t("enter_mobile_number"),
                  life: 3000,
              });
              return false;
            }

            if (this.product.mobile_number) 
                {   
                  if (!mobile_reg.test(this.product.mobile_number)) 
                  {
                          this.$toast.add({
                          severity: "error",
                          summary: "error",
                         // detail: "Please Enter Valid Mobile Number",
                          detail:this.$t("enter_valid_mobile_number"),
                          life: 3000,
                      });
                      return false;
                  }                
                }

            if (this.countryid=="") 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
                //detail: "Please Enter Country Code",
                detail:this.$t("enter_country_code"),
                life: 3000,
            });
            return false;
            }

            if (!this.product.about_us) 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
                //detail: "Please Enter About Client",
                detail:this.$t("enter_about_us"),
                life: 3000,
            });
            return false;
            }

           
            if (!this.product.address) 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
                //detail: "Please Enter address",
                detail:this.$t("enter_address"),
                life: 3000,
            });
            return false;
            }

            if (!this.product.current_version) 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
                //detail: "Please Enter Current Version",
                detail:this.$t("enter_current_version"),
                life: 3000,
            });
            return false;
            }

            if (!this.product.facebook_url) 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
               // detail: "Please Enter Facebook URL",
                detail:this.$t("enter_facebook_url"),
                life: 3000,
            });
            return false;
            }
            

            if (!this.product.twitter_url) 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
                //detail: "Please Enter Twitter URL",
                detail:this.$t("enter_twitter_url"),
                life: 3000,
            });
            return false;
            }

            if (!this.product.linkedin_url) 
            {
            this.$toast.add({   
                severity: "error",
                summary: "error",
                //detail: "Please Enter LinkedIn URL",
                detail:this.$t("enter_linkedin_url"),
                life: 3000,
            });
            return false;
            }           


            var data = {
                        
                            name: this.product.name,
                            application_name: this.product.application_name,
                            email_id: this.product.email_id,
                            mobile_number: this.product.mobile_number,
                            // country_code: this.product.country_code,
                            country_code: this.countryid,
                            about_us: this.product.about_us,
                            address: this.product.address,
                            logo: this.product.logo,
                            fevicon: this.product.fevicon,
                            current_version: this.product.current_version,
                            status: this.product.status,
                            facebook_url: this.product.facebook_url,
                            linkedin_url: this.product.linkedin_url,
                            twitter_url: this.product.twitter_url,
                            user_id: localStorage.getItem("id"),
                            status:"Active",
                            lang:this.$i18n.locale,
            };

            if (this.product._id) 
            {
                data['client_id']=this.product._id;
                data['status']=this.product.status;
                this.isLoadingModel=true;
                var promise = apis.updateClientProfile(data);
                promise.then((response) => {
                    this.isLoadingModel=false;
                    this.productDialog=false;
                    this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                    this.get_count();
                    this.get_list();
                });
        
            } 
            else
            {
                this.isLoadingModel=true;
                var promise = apis.addclient(data);
                promise.then((response) => {
                    this.isLoadingModel=false;
                    this.productDialog=false;
                    this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                    this.get_count();
                    this.get_list();
                });
            }

           
        },
        async handleFileUpload1(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                  this.product.logo="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                 
                   
                });
            });

        },
        async handleFileUpload2(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                    this.product.fevicon="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                
                   
                });
            });

        },
      
      async search(count) {
        if (count == 2) {
          this.search_filter="";
        }
        await this.get_list();
        await this.get_count();
      },
      async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
        // console.log(event);
        //  await this.get_count();
        if (this.totalRecords > 0) {
          this.get_list();
        }
      },
      get_count: function () {
        var data = {
          count: true,
          client_id:"",
          search:this.search_filter,
          user_id: localStorage.getItem("id"),
        };
        this.loading = true;
        var promise = apis.getClientList(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data.data;
          //alert(this.totalRecords);
        });
        console.log(data);
      },
      get_list: function () {
        var data = {
          limit: this.limit,
          page_no: this.page_no,
          count: false,
          client_id: "",
          search:this.search_filter,
          user_id: localStorage.getItem("id"),
        };
        this.loading = true;
        var promise = apis.getClientList(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        console.log(data);
      },
      async date_time_prefix() 
        {
            // Get the current date and time
            const currentDateTime = new Date();

            // Format the date as YYYY_MM_DD
            const formattedDate = currentDateTime.toISOString().split('T')[0].replace(/-/g, '_');

            // Format the time as HH_mm_ss
            const formattedTime = currentDateTime.toTimeString().split(' ')[0].replace(/:/g, '_');

            // Construct the final string
            const resultString = `${formattedDate}_${formattedTime}`;

            return resultString;
        },
      openNew() {
        this.product = {};
        this.submitted = false;
        this.countryid="";
        this.country_selected="";
        this.productDialog = true;
      },
      async editProduct(product) {
        this.file_attachment = "";
        this.product = { ...product };
        if (this.product.country_code) 
        {
            for (let index = 0; index < this.country_data.length; index++) 
            {
                if (this.country_data[index]['dial_code']==this.product.country_code) 
                {
                  this.country_selected=this.country_data[index];
                 
                }
              
            }
        }

        // console.log("🚀 ~ editProduct ~ this.country_selected:", this.product.country_code)
        this.productDialog = true;
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.product.remark='';
        this.deleteProductDialog = true;
      },
      deleteclient() {
        if (this.product._id) {
          var data = {
            "client_id": localStorage.getItem("client_id"),
            "id":this.product._id,
            "user_id":localStorage.getItem("id"),
            "remark":this.product.remark,
          };
          this.isLoadingModel = true;
          var promise = apis.deleteclient(data);
          promise.then((responseapi) => {
            this.isLoadingModel = false;
            if (responseapi.data.status == true) {
              // this.hideDialog1();
             // this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
              this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: responseapi.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });
              this.deleteProductDialog = false;
              
              this.get_list();
              this.get_count();
            } else {
              this.$swal("error to Delete Client");
            }
          });
        }
      },
      async exportCSV() {
        this.limit = this.totalRecords;
        this.get_list();
        await this.$refs.dt.exportCSV();
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
    components: {  },
  };
  </script>
    <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  </style>
    